@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap');

h1 {
	color:black;
	text-align: center;
}

.t-header{
	margin-left: .5rem;
	margin-right: .5rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.t-butt-logo{
	margin: 0.5em;
}

.t-logo{
	margin: 0.5em;
}

.t-socials{
	margin-top: auto;
	margin-bottom: auto;
}

.t-main-div{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
}

.t-main{
	display: flex;
	position: relative;
	top: -10px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
}

.t-main-text-div{

}

.t-main-text{
	margin-top: 2.313rem;
}

.t-gif-div{
	display: flex;
}

.t-main-gif{
	max-width: 100%;
	position: relative;
}

.t-line{
	width: 100%;
	display: flex;
	position: absolute;
	align-items: flex-start;
	justify-content: center;
	margin-top: 0;
	padding-top: 0;
}

.t-text-div{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.t-text-div p{
	display: inline-block;
	font-size: 24px;
	transform: scale(1, 1);
	margin-top: 0.5rem;
	margin-bottom: 0;
	font-family: "Archivo Narrow", 'Arial Narrow', Arial;
	font-weight: bold;
}

.t-main-button{
	margin-top: 1.2rem;
	margin-bottom: 1.3rem;
}

.t-main-button:hover{
	cursor: pointer;
}

.t-bottom{
	max-width: 386px;
	min-width: 382px;
	width: 50%;
	display: flex;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	vertical-align: middle;
	align-items: center;
}
