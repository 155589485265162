@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap');

h1 {
	color:black;
	text-align: center;
}

.header{
	margin-top: 0.5rem;
	margin-left: 1.7rem;
	margin-right: 1.7rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

}

.logo{
	margin: 0.5em;
}

.socials{
	margin-top: auto;
	margin-bottom: auto;
}

.main-div{
	display: flex;
	justify-content: center;
	align-items: flex-end;
	position: relative;
	top: -20px;
}

.main{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 0;
	padding-bottom: 0;
	margin-right: 18%;
	margin-left: 14%;
}

.left-div{
	display: flex;
}

.right-div{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.right-div p{
	display: inline-block;
	font-size: 21px;
	transform: scale(1.15, 1);
	margin-top: 0.5rem;
	margin-bottom: 0;
	font-family: "Archivo Narrow", 'Arial Narrow', Arial;
	font-weight: bold;

}

.main-button{
	margin-top: 1.4rem;
	margin-bottom: 2rem;
}

.main-button:hover{
	cursor: pointer;
}

.main-gif{
	max-width: 100%;
	min-width: 450px;
	min-height: 450px;
	position: relative;
	bottom: 3px;
}

.line{
	width: 100%;
	display: flex;
	position: absolute;
	align-items: flex-start;
	justify-content: center;
	margin-top: 0;
	padding-top: 0;
}


.bottom{
	min-width: 376px;
	max-width: 392px;
	width: 30%;
	margin-top: 0.25rem;
	display: flex;
	justify-content: space-evenly;
	margin-left: auto;
	margin-right: auto;
	vertical-align: middle;
	align-items: center;

}

.main-text{
	margin-top: 8.313rem;
}

.description{
	font-size: 26px;
	text-align: center;
}