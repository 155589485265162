@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap');

h1 {
	color:black;
	text-align: center;
}

.m-body{
	min-width: 0;
	min-height: 0;
}

.m-header{
	margin-top: 0;
	margin-left: 0.25rem;
	margin-right: .25rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.m-butt-div{
	margin-right: 0.5em;
}

.m-butt-logo{
	margin: 0.5em;
}

.m-logo{
	margin: 0.25em;
}

.m-socials{
	margin-top: auto;
	margin-bottom: auto;
}

.m-main-div{
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
}

.m-main{
	display: flex;
	position: relative;
	top: -10px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
}

.m-main-text-div{

}

.m-main-text{
	margin-top: 0;
}

.m-gif-div{
	display: flex;
}

.m-main-gif{
	max-width: 100%;
	min-width: 270px;
	min-height: 270px;
	position: relative;
}

.m-line{
	width: 100%;
	display: flex;
	position: absolute;
	align-items: flex-start;
	justify-content: center;
	margin-top: 0;
	padding-top: 0;
}

.m-text-div{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.m-text-div p{
	display: inline-block;
	font-size: 18.5px;
	transform: scale(1, 1);
	margin-top: 0.5rem;
	margin-bottom: 0;
	font-family: "Archivo Narrow", 'Arial Narrow', Arial;
	font-weight: bold;

}

.m-main-button{
	margin-top: 1rem;
	margin-bottom: 0.7rem;
}

.m-main-button:hover{
	cursor: pointer;
}

.m-bottom{
	min-width: 256px;
	width: 72%;
	max-width: 270px;
	display: flex;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	vertical-align: middle;
	align-items: center;

}
