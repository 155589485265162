@import "https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap";
@import "https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap";
@import "https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap";
h1 {
	color:black;
	text-align: center;
}

.header{
	margin-top: 0.5rem;
	margin-left: 1.7rem;
	margin-right: 1.7rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

}

.logo{
	margin: 0.5em;
}

.socials{
	margin-top: auto;
	margin-bottom: auto;
}

.main-div{
	display: flex;
	justify-content: center;
	align-items: flex-end;
	position: relative;
	top: -20px;
}

.main{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 0;
	padding-bottom: 0;
	margin-right: 18%;
	margin-left: 14%;
}

.left-div{
	display: flex;
}

.right-div{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.right-div p{
	display: inline-block;
	font-size: 21px;
	transform: scale(1.15, 1);
	margin-top: 0.5rem;
	margin-bottom: 0;
	font-family: "Archivo Narrow", 'Arial Narrow', Arial;
	font-weight: bold;

}

.main-button{
	margin-top: 1.4rem;
	margin-bottom: 2rem;
}

.main-button:hover{
	cursor: pointer;
}

.main-gif{
	max-width: 100%;
	min-width: 450px;
	min-height: 450px;
	position: relative;
	bottom: 3px;
}

.line{
	width: 100%;
	display: flex;
	position: absolute;
	align-items: flex-start;
	justify-content: center;
	margin-top: 0;
	padding-top: 0;
}

.bottom{
	min-width: 376px;
	max-width: 392px;
	width: 30%;
	margin-top: 0.25rem;
	display: flex;
	justify-content: space-evenly;
	margin-left: auto;
	margin-right: auto;
	vertical-align: middle;
	align-items: center;

}

.main-text{
	margin-top: 8.313rem;
}

.description{
	font-size: 26px;
	text-align: center;
}
h1 {
	color:black;
	text-align: center;
}

.t-header{
	margin-left: .5rem;
	margin-right: .5rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.t-butt-logo{
	margin: 0.5em;
}

.t-logo{
	margin: 0.5em;
}

.t-socials{
	margin-top: auto;
	margin-bottom: auto;
}

.t-main-div{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
}

.t-main{
	display: flex;
	position: relative;
	top: -10px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
}

.t-main-text-div{

}

.t-main-text{
	margin-top: 2.313rem;
}

.t-gif-div{
	display: flex;
}

.t-main-gif{
	max-width: 100%;
	position: relative;
}

.t-line{
	width: 100%;
	display: flex;
	position: absolute;
	align-items: flex-start;
	justify-content: center;
	margin-top: 0;
	padding-top: 0;
}

.t-text-div{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.t-text-div p{
	display: inline-block;
	font-size: 24px;
	transform: scale(1, 1);
	margin-top: 0.5rem;
	margin-bottom: 0;
	font-family: "Archivo Narrow", 'Arial Narrow', Arial;
	font-weight: bold;
}

.t-main-button{
	margin-top: 1.2rem;
	margin-bottom: 1.3rem;
}

.t-main-button:hover{
	cursor: pointer;
}

.t-bottom{
	max-width: 386px;
	min-width: 382px;
	width: 50%;
	display: flex;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	vertical-align: middle;
	align-items: center;
}

h1 {
	color:black;
	text-align: center;
}

.m-body{
	min-width: 0;
	min-height: 0;
}

.m-header{
	margin-top: 0;
	margin-left: 0.25rem;
	margin-right: .25rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.m-butt-div{
	margin-right: 0.5em;
}

.m-butt-logo{
	margin: 0.5em;
}

.m-logo{
	margin: 0.25em;
}

.m-socials{
	margin-top: auto;
	margin-bottom: auto;
}

.m-main-div{
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
}

.m-main{
	display: flex;
	position: relative;
	top: -10px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
}

.m-main-text-div{

}

.m-main-text{
	margin-top: 0;
}

.m-gif-div{
	display: flex;
}

.m-main-gif{
	max-width: 100%;
	min-width: 270px;
	min-height: 270px;
	position: relative;
}

.m-line{
	width: 100%;
	display: flex;
	position: absolute;
	align-items: flex-start;
	justify-content: center;
	margin-top: 0;
	padding-top: 0;
}

.m-text-div{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.m-text-div p{
	display: inline-block;
	font-size: 18.5px;
	transform: scale(1, 1);
	margin-top: 0.5rem;
	margin-bottom: 0;
	font-family: "Archivo Narrow", 'Arial Narrow', Arial;
	font-weight: bold;

}

.m-main-button{
	margin-top: 1rem;
	margin-bottom: 0.7rem;
}

.m-main-button:hover{
	cursor: pointer;
}

.m-bottom{
	min-width: 256px;
	width: 72%;
	max-width: 270px;
	display: flex;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	vertical-align: middle;
	align-items: center;

}

/*# sourceMappingURL=index.f1ac1148.css.map */
